import { useState, useEffect, Fragment, useContext } from 'react';
// import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import bleatMobileBlack from '../../Assets/Images/logo-bleat-mobile.png';
import bleatMobileWhite from '../../Assets/Images/logo-bleat-mobile-white.png';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { useLocation } from "react-router-dom";
import AdditionalQuestionContext from '../../Store/AdditionalQuestionContext';


const Footer = props => {

  const pdfCtx                 = useContext(AdditionalQuestionContext);
  const { isExportingPDF }     = pdfCtx;
  const { windowWidth }        = useWindowDimensions();
  const currentLocation        = useLocation();
  const currentPath            = currentLocation?.pathname?.split("/")[1];

  const [logo, setLogo]        = useState(bleatMobileBlack);
  
  // console.log(currentLocation);
  // console.log(currentLocation.pathname.split("/")[1]);

  useEffect(() => {

    // paths with dark background
    const pathsWithDarkBg = ["assessment", "details", "results", "invite-team", "invite-team-thanks", "call-request-success", "dashboard"];
    
    pathsWithDarkBg.includes(currentPath) ?
    setLogo(bleatMobileWhite) : setLogo(bleatMobileBlack);
    
  }, [currentLocation, currentPath, isExportingPDF]);
  
  const pathsWithoutFooter = ["before-you-start"];

  // console.log("isExportingPDF", isExportingPDF);
  
  useEffect(() => {
    if(isExportingPDF) {
      setLogo(bleatMobileBlack);
    }
  }, [isExportingPDF])

  return <Fragment>
    <footer>

      {/* {windowWidth <= 830 && } */}
      { pathsWithoutFooter.includes(currentPath) ? null : (
        <div className="bleat-logo-mobile">
          <img src={logo} alt="bleat logo" height="43px" width="auto" />
        </div>) }

      {/* <div className="bleat-logo-mobile">
        <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
      </div> */}

    </footer>
  </Fragment>
}

export default Footer;