import React, { useState, useEffect, useRef, Fragment, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import testimonialImage from "../Assets/Images/testimonial.png";
import quotationImage from "../Assets/Images/quotation.svg";
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import ScheduleCallThumbnail from "../Assets/Images/video-thumb-cropped.jpg"
import { Accordion, AccordionDetails, AccordionSummary, Divider, LinearProgress, CircularProgress, Tab, Tabs, TextField, Button, Box } from "@mui/material";

import { Add, Download, ExpandMore, Info, Remove } from "@mui/icons-material";
import "../App.scss";
import AdditionalQuestionContext from "../Store/AdditionalQuestionContext";

// import prevArrow from "../Assets/Images/prev-arrow.svg";
// import nextArrow from "../Assets/Images/next-arrow.svg";
// import analyzeIcon from '../Assets/Images/insights.svg';
// import alignmentIcon from '../Assets/Images/align.svg';
// import advanceIcon from '../Assets/Images/advance.svg';


const Results = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  const focusCallRef              = useRef();
  const pdfCtx                    = useContext(AdditionalQuestionContext);

  const { isExportingPDF, updateIsExportingPDF } = pdfCtx;
  
  // console.log("location hash", window.location.hash);
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isCallRequested, setIsCallRequested] = useState(false);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0, 0]);

  const [downloadLink, setDownloadLink] = useState("");

  const [toggleIndividual, setToggleIndividual]		= useState(0);
  const [toggleSection, setToggleSection]		= useState(1);
	const [toggleMore, setToggleMore] 				= useState(0);	
	const [sections, setSections]   					= useState([]);
  const [assessmentInfo, setAssessmentInfo] = useState([]);
  const [answers, setAnswers]               = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [tabValue, setTabValue]             = useState(0);
  const [pdfClass, setPdfClass]             = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box className="inner-tab-box" sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // scroll to call section if url has #call location hash 
  useEffect(() => {
    function scrollToFocus() {
      if (window.location.hash === "#call") {
        focusCallRef?.current?.scrollIntoView({ behavior: "smooth" });
        focusCallRef?.current?.focus();
      }
    }
    let timer = setTimeout(() => {
      scrollToFocus();
    }, 1500);

    return () => clearTimeout(timer);
  }, [focusCallRef]);


  // console.log("answers", answers);
  // console.log("sections", sections);
  // console.log("questions", questions);

  useEffect(() => {
    document.body.style.backgroundColor = "#303447";

    fetchResults();

    // const getCSVDownloadLink = () => {
    //   // setLoadingBtn(true);
    //   fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id + "/dashboard/export", {
    //     method: "GET",
    //     mode: "cors",
    //     headers: { "Content-Type": "application/json" },
    //   })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if (data.status === "success") {
    //       console.log("getting CSV link...\n", data);
    //       setDownloadLink(data.exportURL);
    //       // setLoadingBtn(false);
    //     } else {
    //       // setOpenSnackbar(true);
    //     }
    //   }).catch((err) => console.error(err));
    // }

    // getCSVDownloadLink();

  }, []);

  // useEffect(() => {
  //   if (isCallRequested) {
  //     let url = "/call-request-success/" + id;
  //     console.log(url);

  //     window.open(url, "_blank");
  //   }
  // }, [id, isCallRequested]);

  // to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6])

  const fetchResults = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setName(data.name);
          setScore(data.score);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setSubScore6(data.subScore6);
          setIsMember(data.isTeamMember);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error(err));

      setIsLoading(true);
      fetch(process.env.REACT_APP_API_URI + "/v1/assessment/dashboard/" + id, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            console.log(data);
            setAssessmentInfo(data.assessmentInfo);
            setAnswers(data.assessmentInfo[0].answers);
            setQuestions(data.questionInfo);
            setSections(data.sectionInfo);
            setIsLoading(false);
          } else {
            // setOpenSnackbar(true);
          }
        }).catch((err) => console.error(err));
  };

  const requestCall = () => {

    setIsRequesting(true);
    const newWindowReference = window.open();

    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/request-call", {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({uniqueId: id, assessmentId: process.env.REACT_APP_CLIENT_ID})
    })
    .then(response => response.json())
    .then((data) => {
      setIsRequesting(false);
      if (data.status === "success") {
        setIsCallRequested(true);
        console.log(data);
        let url = "/call-request-success/" + id;
        console.log(url);
        newWindowReference.location = url;
        // window.open(url, "_blank")
      } else {
          newWindowReference.close();
          setIsCallRequested(false);
          setIsRequesting(false);
        // setOpenSnackbar(true);
      }
    }).catch(err => {
        console.error("POST req error\n", err);
        newWindowReference.close();
        setIsCallRequested(false);
        setIsRequesting(false);
    })
  }

  

  const showTitleText = () => {
      return <h1><span className="user-name">{name}</span>, your Activation score is {Math.round(score * 1.26)}</h1>
  };

  const showScoreText = () => {
    if (Math.round(score * 1.26) > 63 && Math.round(score * 1.26) <= 126) {
      return <div className="result-text">
        <p>Congratulations!<strong> Your church is doing a great job</strong> at activating the Great Commission and driving Kingdom impact. With the right resources and prioritized focus, you can do even better!</p>
      </div>
    } else if (Math.round(score * 1.26) > 21 && Math.round(score * 1.26) <= 63) {
      return <div className="result-text">
        <p><strong>Your church is doing well but not functioning at its best.</strong> There's room for improvement, which is a good thing as it gives you an opportunity to take intentional steps toward being effective.</p>
      </div>
    } else if (Math.round(score * 1.26) >= 0 && Math.round(score * 1.26) <= 21) {
      return <div className="result-text">
        <p><strong>Your church needs improvement</strong> at activating the Great Commission and driving Kingdom impact. With the right resources and prioritized focus, you can do even better!</p>
      </div>
    }
  };

  // const showProgressBarText = (scoreValue) => {
  //   if (scoreValue >= 95) {
  //     return "Enjoying health";
  //   } else if (scoreValue >= 80 && scoreValue < 95) {
  //     return "Improving health";
  //   } else if (scoreValue >= 70 && scoreValue < 80) {
  //     return "Pursuing health";
  //   } else if (scoreValue < 70) {
  //     return "Battling unhealthiness";
  //   }
  // }

  const showRequestCall = () => {
    return (
      <div className="request-call">
        <h3 ref={focusCallRef}>Talk with the Intentional Churches team</h3>
        <p>Discuss the right next steps for you and your team as you continue your ChurchOS Journey!</p>
        <div className="cta-btn-container">
          <div className="cta-btn">
            <Link to="#">
              { isCallRequested ? 
                <><Button disabled>DONE</Button></> : <>{ isRequesting ?
                  <Button>REQUEST A CALL</Button> :
                  <Button onClick={requestCall}>REQUEST A CALL</Button>
                }</>
              }
            </Link>
          </div>
          
          { windowWidth > 830 && <div className="tooltip">
            <Info className="call-disclaimer-info-icon" />
            <span className="tooltiptext">Clicking this button will submit your call request.</span>
          </div> }

        </div>
        { windowWidth <= 830 && <p className="call-disclaimer"><span>*</span>Please note that clicking this button will submit your call request.</p> }

      </div>
    )
  }

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this assessment</h3>
        <p>Gain insights into how your church is collectively activating the Great Commission by inviting your team to take this assessment!</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (windowWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="thumbnail"><img src={ScheduleCallThumbnail} alt="" srcSet="" /></div>
          <h2>Schedule a free call with Alan</h2>
          Schedule a call with Alan to debrief your test results and take the next step towards supercharging your digital ministry strategy!
          <div className="cta-btn">
            <a
              href="https://calendly.com/reachalangeorge"
              target="_blank"
            >
              <Button>TALK TO ALAN</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Gain new insights into your team's alignment with your school board by inviting them to take this test!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE MY BOARD</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  // for combining question, answer, section name all in one array for score wise red, yellow, green accordions 
  const questionsAnswersCombined = questions?.map(question => {
    let currentAnswer = answers?.find(ans => ans.question_id === question.id);
    return ({ ...question, chosenAnswer: currentAnswer?.answer });
  })
  const queAnsAndSections = questionsAnswersCombined?.map(qa => {
    let currentSection = sections?.find(sec => sec.id === qa.sectionId);
    return ({ ...qa, sectionId: currentSection?.id, sectionName: currentSection?.section });
  })
  // red, yellow, green respectively 
  const scoreWiseAnswers = [ [], [], [] ];
  
  for (let i = 0; i < queAnsAndSections.length; i++) {
    if(queAnsAndSections[i].chosenAnswer === 1) {
      scoreWiseAnswers[0].push(queAnsAndSections[i]);
    } else if (queAnsAndSections[i].chosenAnswer === 3) {
      scoreWiseAnswers[1].push(queAnsAndSections[i]);
    } else if (queAnsAndSections[i].chosenAnswer === 6) {
      scoreWiseAnswers[2].push(queAnsAndSections[i]);
    }
  }

  // console.log(queAnsAndSections);
  // console.log(scoreWiseAnswers);

  const accordionColor = (num) => {
    switch (num) {
      case 0:
        return "accordion red";
      case 1:
        return "accordion yellow";
      case 2:
        return "accordion green";
      default:
        return "accordion"
    }
  }

  const exportPDF = () => {

    updateIsExportingPDF(true);
    setPdfClass(true);
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=1200");
    document.querySelector("#root").classList.add("pdf-export-results");
    document.querySelector("div.App").setAttribute("id", "pdf-conversion");
    
    setTimeout(function() {
      console.log("delayed function");
      
      // html2canvas((document.querySelector("#root")), { scale: 3 }).then(canvas => {
      //   // if you want see your screenshot in body.
      //   // document.body.appendChild(canvas);
      //   const imgData = canvas.toDataURL('image/svg');
      //   // const pdf = new jsPDF("p", "mm", "a4");
      //   const pdf = new jsPDF({ orientation: "p", unit: "px", format: "a4"});
      //   const imgProperties = pdf.getImageProperties(imgData);

      //   let pageWidth = pdf.internal.pageSize.getWidth();
      //   // let pageHeight = (imgProperties.height * pageWidth) / imgProperties.width;
      //   let pageHeight = pdf.internal.pageSize.getHeight();
      //   console.log(pageWidth, pageHeight);
      //   const widthRatio = pageWidth / canvas.width;
      //   // const heightRatio = pageHeight / canvas.height;
      //   const heightRatio = ((imgProperties.height * pageWidth) / imgProperties.width) / canvas.height;
      //   const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        
      //   const canvasWidth = canvas.width * ratio;
      //   // const canvasHeight = canvas.height * ratio;
      //   const canvasHeight = canvas.height;
      //   let heightLeft = canvasHeight;
      //   console.log(canvasWidth, canvasHeight);

      //   const marginX = (pageWidth - canvasWidth) / 2;
      //   const marginY = (pageHeight - canvasHeight) / 2;

      //   console.log("pageHeight", pageHeight);
      //   console.log("canvasHeight", canvasHeight);
      //   console.log("heightLeft", heightLeft);

      //   // console.log(canvas);
      //   // pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
      //   // pdf.addImage(imgData, 'PNG', marginX, 40, canvasWidth, canvasHeight);
      //   // pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);

      //   pdf.addImage(imgData, 'SVG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');
      //   heightLeft -= pageHeight;
        
      //   while(heightLeft > 0) {
      //     pdf.addPage();
      //     pdf.addImage(imgData, 'SVG', 0, (heightLeft - canvasHeight), pageWidth, pageHeight, undefined, 'FAST');
      //     heightLeft -= pageHeight;
      //   }

      //   pdf.save("Individual Results.pdf");
      //   // window.location.reload();
      // });

      // =================================================================

      html2canvas((document.querySelector("#root")), { scale: 3 }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;


        var doc = new jsPDF('p', 'mm', 'a4');
        var position = 0;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        doc.setLineWidth(0);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 297);
        heightLeft -= pageHeight;
        // console.log("canvas.height", canvas.height);
        // console.log("pageHeight", pageHeight);
        // console.log("heightLeft", heightLeft);
        // console.log("imgHeight", imgHeight);
        // console.log("position", position);
        
        
        while (heightLeft >= 0) {
          // console.log("second page");
          // console.log("pageHeight", pageHeight);
          // console.log("heightLeft", heightLeft);
          // console.log("imgHeight", imgHeight);
          position = heightLeft - (imgHeight);
          console.log("position", position);
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
          doc.setLineWidth(0);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 297);
          heightLeft -= pageHeight;
        }
        doc.save('Individual Results.pdf');

        // ======================================================

        // var imgData = canvas.toDataURL('image/png');
        // var imgWidth = 210; 
        // var pageHeight = 295;  
        // var imgHeight = canvas.height * imgWidth / canvas.width;
        // var heightLeft = imgHeight;
        // var doc = new jsPDF('p', 'mm');
        // var position = 0;

        // doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        // heightLeft -= pageHeight;

        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight;
        //   doc.addPage();
        //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        //   heightLeft -= pageHeight;
        // }
        // doc.save( 'file.pdf');

      });

      document.querySelector("div.App").removeAttribute("id");
      document.querySelector("#root").classList.remove("pdf-export-results");
      document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
      setPdfClass(false);
      updateIsExportingPDF(false);

    }, 600);
  
    // clearTimeout(timeoutId);
    // console.log(`Timeout ID ${timeoutId} has been cleared`);


    // document.querySelector("div.App").removeAttribute("id");
    // document.querySelector("#root").classList.remove("pdf-export-results");
    // document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
    // setPdfClass(false); 
    // updateIsExportingPDF(false);
  }

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F7D54D" }} size={60} />
      </div>
    );
  }

  // PDF Export Related Functions & Variables

  let answerCount = 0;
  let longAnswers = 0;
  let addPageBreakSpace = false;
  const checkPageBreak = () => {
    // 13 and 14 are derived from approximate number of lines of text on the page before the need for page break
    // these are 2 consecutive numbers because if the answer is longer, we are adding 1 to answerCount. so if answerCount = 13 and then 1 longAnswer comes up, it may become 14 and entirely skip 13 
    // if we decrease the content above, we will need to increase these numbers to fit in more lines before page break
    // if we increase the content above, we will need to decrease these numbers to fit in less lines before page break
    if((answerCount + longAnswers === 13) || (answerCount + longAnswers === 14)) {
      addPageBreakSpace = true;
      answerCount += 2; // to skip 14 check if count is 13 to avoid 2 spacer divs 
    }
  }
  const pageBreakDiv = () => {
    addPageBreakSpace = false; // reset addPageBreakSpace
    return <div style={{ height: "120px" }}></div>
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: `calc(${score}% - 2px)` }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score * 1.26)}</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp" style={{ minWidth: '17%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '33%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe2" style={{ minWidth: '25%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '17%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '33%' }}></h4>
                {/* <h4 className="eexpe" style={{ minWidth: '50%' }  }></h4> */}
                {/* <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              {/* <div className="pervalue-text">
                <p className="not-aligned" style={{ marginLeft: '9%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Not on<br />Track</p>
                <p className="not-aligned" style={{ marginLeft: '29%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Needs<br />Improvement</p>
                <p className="poor-aligned" style={{ marginLeft: '55%', fontWeight: score > 20 && windowWidth >= 830 ? 400 : 700 }}>Meets<br />Expectations</p>
                <p className="some-aligned" style={{ marginLeft: '79%', fontWeight: score > 40 && windowWidth >= 830 ? 400 : 700 }}>Exceeds<br />Expectations</p>
              </div> */}

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {showScoreText()}

          <div className="areas-for-growth">
            <h2>Evaluate areas for growth in your church</h2>
            {!pdfClass && scoreWiseAnswers?.map((answer, index) => {
              return (
                  // <Accordion key={index} className={accordionColor(index)} expanded={pdfClass} >
                  <Accordion key={index} className={accordionColor(index)} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {
                      index === 0 ? <p className="white-text">{scoreWiseAnswers[0].length} areas that are broken</p> : index === 1 ? <p className="white-text">{scoreWiseAnswers[1].length} areas you need improvement in</p> : index === 2 ? <p className="white-text">{scoreWiseAnswers[2].length} areas where you're activating impact</p> : null
                    }
                  </AccordionSummary>
                  {answer?.map((score, index) => {
                    return (
                      <Fragment key={index}>
                      <AccordionDetails>
                        <p><strong className="section-name">{score?.sectionName?.toLowerCase()}:</strong> {score.question}</p>
                      </AccordionDetails>
                      <Divider />
                      </Fragment>
                    )
                  })}
                </Accordion>
              )})
            }
            {pdfClass && scoreWiseAnswers?.map((answer, index) => {
              return (
                  <div className="print-accordion">
                  <div className={accordionColor(index)}>
                    {
                      index === 0 ? <p className="white-text">{scoreWiseAnswers[0].length} areas that are broken</p> : index === 1 ? <p className="white-text">{scoreWiseAnswers[1].length} areas you need improvement in</p> : index === 2 ? <p className="white-text">{scoreWiseAnswers[2].length} areas where you're activating impact</p> : null
                    }
                    </div>

                  {answer?.map((score, index) => {
                    console.log(answerCount + longAnswers);
                    answerCount += 1;
                    if(score?.question?.length + score?.sectionName?.length > 100) {
                      longAnswers += 1;
                    }
                    checkPageBreak();
                    // if((answerCount + longAnswers === 13) || (answerCount + longAnswers === 14)) {
                    //   addPageBreakSpace = true;
                    //   answerCount += 2; // to skip 14 check if count is 13 
                    // } 
                    return (
                      <Fragment key={index}>
                        <p className="score-info"><strong className="section-name">{score?.sectionName?.toLowerCase()}:</strong> {score.question}</p>
                        <Divider />
                        {addPageBreakSpace && pageBreakDiv()}
                      </Fragment>
                    )
                  })}
                </div>
              )})}
          </div>

          {/* <div className="score-section-container">

            <div className="score-section">
              <p className="score-txt">{subScores[0]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[0]} /></div>
              <p className="score-name">LEADERSHIP</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[1]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[1]} /></div>
              <p className="score-name">SYSTEMS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[2]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[2]} /></div>
              <p className="score-name">COMMUNICATION</p>
            </div>            

            <div className="score-section">
              <p className="score-txt">{subScores[3]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[3]} /></div>
              <p className="score-name">DATA</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[4]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[4]} /></div>
              <p className="score-name">VOLUNTEERS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[5]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[5]} /></div>
              <p className="score-name">COMMUNITY</p>
            </div>

          </div> */}

          <div className="pdf-btn">
            <Button id="pdf-convert-btn" onClick={() => exportPDF()}> <Download className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button>
            {/* <a href={downloadLink} download className="csv-download-link"><Button id="pdf-convert-btn"> <Download className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button></a> */}
          </div>

        </div>
      </div>

      {showInvite()}

      {/* {showTeamMember()} */}

      {!isMember && <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>The ChurchOS Assessment helped our team immediately plunge into meaningful conversation to identify quick action for focus and alignment. This tool helped us make the most effective use of our Executive Retreat as a starting point for discussion and decision-making.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Dr. Matthew Robinson<br />
            {windowWidth <= 830 ? 
              (<><p>Pastor of Administration</p><p>First Baptist Orlando</p></>) : (<span>Pastor of Administration, First Baptist Orlando</span>)}
          </div>

        </div>

      </div>}

      <div className="next-steps">
        <h2>Next steps</h2>
        <p className="subheader">Thank you for being intentional about evaluating your church. By investing time into thinking things through, you've taken a crucial step toward self-discovery.</p>

        <Box sx={{ width: '100%' }}>
          <Box>
          <Tabs className="tabs-container"
            TabIndicatorProps={{style: { backgroundColor: 'transparent' }}}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            textColor="secondary"
            // indicatorColor="#DC4A37"
            indicatorColor="primary"
          >
            <Tab className="tab" label="FOR YOU" style={{ minWidth: "49.5%" }} {...a11yProps(0)} />
            <Tab className="tab" label="FOR TEAMS" style={{ minWidth: "49.5%" }} {...a11yProps(1)} />
            
          </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div className="steps-for-you">

            <h4 className="title"><span className="encircled-num">1</span> See what's working</h4>
            <div className="info-text">
              <p className="list-info">Great self-discovery is driven by great questions. Consider the items in the green list and ask yourself:</p>
              <ul>
                <li>What's the strongest item that you can leverage?</li>
                <li>What's lower in rank and could use improvement or further investment?</li>
              </ul>
            </div>
            <h4 className="title"><span className="encircled-num">2</span> See what needs work</h4>
            <div className="info-text">
              <p>Examine the red and yellow lists and highlight the biggest roadblocks. Consider what you would do about them.</p>
            </div>
            <h4 className="title"><span className="encircled-num">3</span> Get your team involved</h4>
            <div className="info-text">
              <p className="list-info">We strongly recommend that you take this assessment as a team.</p>
              <ul>
                <li>You'd have a common metric to evaluate the church so you're on the same page</li>
                <li>You can all see the results in real time</li>
                <li>Walking through the results together will help you arrive at some actionable steps</li>
              </ul>
              <br/>
              {/* <p>Click <Link className="share-link" to="#">here</Link> to share the assessment with your team.</p> */}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
          <div className="steps-for-you">
            <h4 className="title"><span className="encircled-num">1</span> Compare notes</h4>
            <div className="info-text">
              <p className="list-info">When you take this assessment as a team, the first order of business for you all is to compare notes.</p>
              <ul>
                <li>What was on everyone's list?</li>
                <li>Did you vote or rank items similarly?</li>
                <li>If not, why did you vote the way you voted?</li>
              </ul>
              <p>As you begin the journey of self-discovery as a team, trust in the power of honest and objective conversations and decisions.</p>
            </div>
            <h4 className="title"><span className="encircled-num">2</span> Highlight what's working</h4>
            <div className="info-text">
              <p className="list-info">Go through the items in the green list and select:</p>
              <ul>
                <li>The strongest item in your lists that you can leverage as a church</li>
                <li>An item that's lower in rank and needs improvement or further investment</li>
              </ul>
            </div>
            <h4 className="title"><span className="encircled-num">3</span> Discuss the roadblocks</h4>
            <div className="info-text">
              <p>Highlight the biggest roadblocks in the red and yellow lists, and discuss possible solutions with your team.</p>
            </div>
            <h4 className="title"><span className="encircled-num">4</span> Create an action plan</h4>
            <div className="info-text">
              <p>Assign a team with a leader to create an action plan for each prioritized item.</p>
              <br/>
            </div>
            </div>
          </TabPanel>
        </Box>

      </div>

      {isMember && <div className="last-text">
        <h4>Thank you for taking this assessment!</h4> 
        <p>Please get in touch with your leader for the next step.</p>
      </div>
      }

      {!isMember && showRequestCall()}
      {/* {showSchedule()} */}
      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};
export default Results;
