import { createContext, useState } from 'react';

const AdditionalQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => {},
  updateAnswer: (updateAnswer) => {},
  updateTextAnswer: (updateTextAnswer) => {},
});

export function AdditionalQuestionContextProvider(props) {

  const [questions, setQuestions] = useState({});
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  function updateIsExportingPDF(bool) {
    setIsExportingPDF(bool);
  }

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question      = questions.find(function(obj) { return obj.id == questionId; });
    question.answer   = answer;
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question        = questions.find(function(obj) { return obj.id == questionId; });
    question.answerTxt  = answer;
  }

  const context = {
    questions: questions,
    addQuestions: addQuestionsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    isExportingPDF: isExportingPDF,
    updateIsExportingPDF: updateIsExportingPDF,
  };

  return <AdditionalQuestionContext.Provider value={context}>
    {props.children}
  </AdditionalQuestionContext.Provider>
}

export default AdditionalQuestionContext;
