import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Online Pastor", label: "Online Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const Details = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = React.useState(false);
  const [isLoading, setIsLoading]       = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isMember, setIsMember]       = React.useState(true);

  const [firstName, setFirstName]     = React.useState("");
  const [lastName, setLastName]       = React.useState("");
  const [email, setEmail]             = React.useState("");
  const [jobTitle, setJobTitle]       = React.useState("");
  const [jobTitleMember, setJobTitleMember]       = React.useState("");
  const [churchName, setChurchName]   = React.useState("");
  const [schoolName, setSchoolName]   = React.useState("");
  const [zipCode, setZipCode]         = React.useState("");
  const [firstNameError, setFirstNameError]       = React.useState(false);
  const [lastNameError, setLastNameError]         = React.useState(false);
  const [emailError, setEmailError]               = React.useState(false);
  const [jobTitleError, setJobTitleError]         = React.useState(false);
  const [churchNameError, setChurchNameError]     = React.useState(false);
  const [schoolNameError, setSchoolNameError]     = React.useState(false);
  const [zipCodeError, setZipCodeError]           = React.useState(false);

  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    document.body.style.backgroundColor = "#4B4E5D";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;


  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        // setJobTitle("none");
        setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      console.log(firstName);
      console.log(jobTitle);
      // console.log(jobTitleMember);
      if(firstName != "" && lastName != "") {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
      // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(firstName == "") {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(lastName == "") {
      setLastNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    // if(jobTitle == "none" || !jobTitle) {
    if(jobTitle == "" || !jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(churchName == "") {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName != "" && lastName != "" && jobTitle != "") {
      submitToApi();
    } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{'color': '#fff'}} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please enter your role.</div>
      }
    }
    // if(field == "schoolName") {
    //   if(schoolNameError) {
    //     return <div className="error-text">Please confirm your school name.</div>
    //   }
    // }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#303447");
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#303447";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    // if(field == "schoolName") {
    //   setSchoolNameError(false);
    // }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if(isMember) {
      navigate('/assessment/' + id + '/review?member=' + searchParams.get("member"), { replace: true });
    } else {
      navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    }
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1>Now that's intentional!</h1>
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>
        {/* <p className="mandatory-field-text">All fields are required.</p> */}

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="simon@rockchurch.com" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Role<span className="required-field">*</span></p>

            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle} 
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#30344733',
                  primary: '#303447',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Church name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="North Point Church" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }
          
          {/* {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Hill Crest School" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("schoolName")}
              </div>
            ) : null
          } */}
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP code<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="11618" 
                  className="form-details-text" 
                  value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );
  
}
export default Details;