import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Link, useParams } from "react-router-dom";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { DataGrid } from '@mui/x-data-grid';
import { Slider, LinearProgress, CircularProgress, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Divider, TextField, MenuItem, Button } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import redBlock from '../Assets/Images/red.png';
import yellowBlock from '../Assets/Images/yellow.png';
import greenBlock from '../Assets/Images/green.png';

// import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from './Tabs';
import UrgencyScore from '../Components/UrgencyScore';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import '../App.scss';
import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';
import { Download } from '@mui/icons-material';

const Dashboard = (props) => {

	// to make first table column sticky 
	const useStyles = makeStyles({
		table: {
			minWidth: 650
		},
		sticky: {
			position: "sticky",
			left: 0,
			background: "white",
			boxShadow: "5px 2px 5px grey",
			borderRight: "2px solid black"
		}
	});
  
	const { id }                        			= useParams();
	const pdfCtx                    					= useContext(AdditionalQuestionContext);
  const { updateIsExportingPDF } 						= pdfCtx;
	const [pdfClass, setPdfClass]             = useState(false);

	const [isLoading, setIsLoading]   				= useState(true);
	const [currentView, setCurrentView] 			= useState(0);

  const [toggleIndividual, setToggleIndividual]		= useState(0);
  const [toggleSection, setToggleSection]		= useState(1);
	const [toggleMore, setToggleMore] 				= useState(0);	

	const [members, setMembers]        				= useState([]);
	const [selected, setSelected] 						= useState(0);
	const [questions, setQuestions]   				= useState([]);
	const [sections, setSections]   					= useState([]);
	const [answers, setAnswers]               = useState([]);
	const [hurdles, setHurdles]        				= useState([]);
	const [enablers, setEnablers]     				= useState([]);
	const [tops, setTops]     								= useState([]);
	const [bottoms, setBottoms]     					= useState([]);
	
	const [score, setScore]             			= useState(0);
  const [subScore1, setSubScore1]     			= useState(0);
  const [subScore2, setSubScore2]     			= useState(0);
  const [subScore3, setSubScore3]     			= useState(0);
  const [subScore4, setSubScore4]     			= useState(0);
  const [subScore5, setSubScore5]     			= useState(0);
  const [subScore6, setSubScore6]     			= useState(0);
  const [subScore7, setSubScore7]     			= useState(0);
  const [subScore8, setSubScore8]     			= useState(0);
  const [urgencyVote1, setUrgencyVote1]			= useState(0);
  const [urgencyVote2, setUrgencyVote2]			= useState(0);
  const [urgencyVote3, setUrgencyVote3]			= useState(0);
  const [urgencyVote4, setUrgencyVote4]			= useState(0);
  const [urgencyVote5, setUrgencyVote5]			= useState(0);
  const [urgencyScore, setUrgencyScore]			= useState(0);
	const [memberSection1, setMemberSection1] = useState([]);
	const [memberSection2, setMemberSection2] = useState([]);
	const [memberSection3, setMemberSection3] = useState([]);
	const [memberSection4, setMemberSection4] = useState([]);

	const [memberScore, setMemberScore] 			= useState(0);
	const [memberName, setMemberName] 				= useState(0);
  const [memSubScore1, setMemSubScore1]			= useState(0);
  const [memSubScore2, setMemSubScore2]			= useState(0);
  const [memSubScore3, setMemSubScore3]			= useState(0);
  const [memSubScore4, setMemSubScore4]			= useState(0);
  const [memSubScore5, setMemSubScore5]			= useState(0);
  const [memSubScore6, setMemSubScore6]			= useState(0);
  const [memSubScore7, setMemSubScore7]			= useState(0);
  const [memSubScore8, setMemSubScore8]			= useState(0);
  const [memUrgencyScore, setMemUrgencyScore]	= useState(0);
	const [accordionData, setAccordionData]   = useState([]);
	const [downloadLink, setDownloadLink]			= useState("");

	const { windowWidth } = useWindowDimensions();

	const scores = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8];
	const memScores = [memSubScore1, memSubScore2, memSubScore3, memSubScore4, memSubScore5, memSubScore6, memSubScore7, memSubScore8];
	
	let urgencyPercent 			= 60.0;
	if(window.innerWidth > 650) {
		urgencyPercent 				= 63.9;
	}

	useEffect(() => {
		document.body.style.backgroundColor = "#303447";
    fetchResults();
		fetchNestedAccordionData();
		
		const getCSVDownloadLink = () => {
      // setLoadingBtn(true);
      fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id + "/dashboard/export", {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log("getting CSV link...\n", data);
          setDownloadLink(data.exportURL);
          // setLoadingBtn(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error(err));
    }

    getCSVDownloadLink();

  }, []);

	const fetchNestedAccordionData = () => {
		fetch(process.env.REACT_APP_API_URI + '/v2/dashboard/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status === "success") {
				setAccordionData(data.data);
			}
		}).catch(err => console.error(err));
	}

	// useEffect(() => {
	// 	setAccordionData(accordionData?.map((accData, index) => {
	// 		let questionAvgScore = 0;
	// 		let totalAnswers = accData?.answers?.length;
	// 		accData?.answers?.forEach(answer => {
	// 			switch (answer.answer) {
	// 				case 1: questionAvgScore += 1;
	// 					break;
	// 				case 3: questionAvgScore += 2;
	// 					break;
	// 				case 6: questionAvgScore += 3;
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 		})
	// 		questionAvgScore = questionAvgScore/totalAnswers;
	// 		return { ...accData, questionAvgScore: questionAvgScore }
	// 	}))
	// }, [accordionData.length]);

	const accDataWithAvgScores = accordionData?.map((accData, index) => {
		let questionAvgScore = 0;
		let totalAnswers = accData?.answers?.length;
		accData?.answers?.forEach(answer => {
			switch (answer.answer) {
				case 1: questionAvgScore += 1;
					break;
				case 3: questionAvgScore += 2;
					break;
				case 6: questionAvgScore += 3;
					break;
				default:
					break;
			}
		})
		questionAvgScore = questionAvgScore/totalAnswers;
		return { ...accData, questionAvgScore: questionAvgScore }
	})
	accDataWithAvgScores?.sort((a,b) => a.questionScore - b.questionScore);

	console.log("accDataWithAvgScores", accDataWithAvgScores);

  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
				setUrgencyScore(data.urgencyScore);
				setMembers(data.assessmentInfo);
				setQuestions(data.questionInfo);
				setSections(data.sectionInfo);
				setHurdles(data.hurdles);
				setEnablers(data.enablers);
				setAnswers(data.assessmentInfo[0].answers);
				
				// console.log(data.questionInfo);

				setSelected(data.assessmentInfo[0].id);
				setMemberScore(data.assessmentInfo[0].score);
				setMemberName(data.assessmentInfo[0].fullName);
				setMemSubScore1(data.assessmentInfo[0].subScore1);
				setMemSubScore2(data.assessmentInfo[0].subScore2);
				setMemSubScore3(data.assessmentInfo[0].subScore3);
				setMemSubScore4(data.assessmentInfo[0].subScore4);
				setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);

				let urgencyVote1  = 0;
				let urgencyVote2  = 0;
				let urgencyVote3  = 0;
				let urgencyVote4  = 0;
				let urgencyVote5  = 0;

				let section1Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 1; });
				section1Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection1(section1Questions);
				
				let section2Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 2; });
				section2Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection2(section2Questions);

				let section3Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 3; });
				section3Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection3(section3Questions);
				
				let section4Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 4; });
				section4Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection4(section4Questions);
				
				let tops 					= data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
				let bottoms 			= data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

				let urgVote1  		= 0;
				let urgVote2  		= 0;
				let urgVote3  		= 0;
				let urgVote4  		= 0;
				let urgVote5  		= 0;

				data.assessmentInfo.forEach((member) => {
					if(member.addAnswer1 == 1) {
						urgVote1++;
					} else if(member.addAnswer1 == 2) {
						urgVote2++;
					} else if(member.addAnswer1 == 3) {
						urgVote3++;
					} else if(member.addAnswer1 == 4) {
						urgVote4++;
					} else if(member.addAnswer1 == 5) {
						urgVote5++;
					}
				});

				setUrgencyVote1(urgVote1);
				setUrgencyVote2(urgVote2);
				setUrgencyVote3(urgVote3);
				setUrgencyVote4(urgVote4);
				setUrgencyVote5(urgVote5);

				setTops(tops.slice(0, 3));
				setBottoms(bottoms.slice(0, 3));
				setIsLoading(false);

				// console.log(tops);
				// console.log(bottoms);
				// console.log(questions);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

	// let getMemberNameCols = members?.map((member, index) => {
	// 	let field = "member";
	// 	// member1, member2, member3, etc fields 
	// 	return { field: (field + (index+1)).toString() };
	// })
	// let getMemberNameRows = members?.map((member, index) => {
	// 	let field = "member";
	// 	let rowFieldName = field + (index+1).toString();

	// 	// return { rowFieldName: "" };
	// 	return getMemberNameCols.map(item => Object.values(item));
	// })

	// sections.forEach(section => {
	// 	getMemberNameCols.map(memberNameCol => {
	// 		Object.values(memberNameCol)
	// 	})
	// })

	// console.log("getMemberNameRows", getMemberNameRows);
	// console.log("getMemberNameCols", getMemberNameCols);
	// console.log("members", members);
	// let memberColumnsFromApi = members?.map((member, index) => {
	// 	return ({ ...getMemberNameCols[index], headerName: member.fullName.split(" ")[0], width: 250, align: "center", headerAlign: "center" })
	// })
	// let memberColumns = [
  //   { field: 'section', headerName: 'Team Member\nActivation Score', width: 250, align: "center", headerAlign: 'center', },
	// 	...memberColumnsFromApi,
	// ]

	// const memberRows = members?.map((member, index) => ({id: index + 1, section: sections[index].section, member: member?.answers?.[index]?.answer }))
	// const sectionRows = sections?.map((section, index) => ({ id: index + 1, section: section.section, member: "" }))
	// const memberRows = members.map(member => member);

	// for (let i = 0; i < sectionRows.length; i++) {
	// 	let score = 0;
	// 	for (let j = 0; j < members.length; j++) {
	// 		console.log(members[j].answers[i].answer);
	// 		score = members[j].answers[i].answer;
	// 	}
	// 	sectionRows[i].`member${i+1}` = score;
	// }

	// const rows = members.map((member, index) => {
	// 	return member.answers.map((ans, idx) => ( ans.answer ))
	// });

	// console.log("memberColumns", memberColumns);
	// console.log("sectionRows", rows);

	const urgencyComponentProps = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5 };

	const showTitleText = () => {
    if (score > 80) {
      return <h1><span className="user-name">{memberName}</span>, it looks like your Head and Board are highly aligned.</h1>
    } else if (score > 60 && score <= 80) {
      return <h1><span className="user-name">{memberName}</span>, it looks like your Head and Board are well aligned.</h1>
    } else if (score > 40 && score <= 60) {
      return <h1><span className="user-name">{memberName}</span>, it looks like your Head and Board might be somewhat aligned.</h1>
    } else if (score > 20 && score <= 40) {
      return <h1><span className="user-name">{memberName}</span>, it looks like your Head and Board might be poorly aligned.</h1>
    } else if (score >= 0 && score <= 20) {
      return <h1><span className="user-name">{memberName}</span>, it looks like your Head and Board might not be aligned.</h1>
    }
  };

	const showScoreText = () => {
    if (Math.round(score * 1.26) > 63 && Math.round(score * 1.26) <= 126) {
      return <div className="result-text">
        <p>Congratulations!<strong> Your church is doing a great job</strong> at activating the Great Commission and driving Kingdom impact. With the right resources and prioritized focus, you can do even better!</p>
      </div>
    } else if (Math.round(score * 1.26) > 21 && Math.round(score * 1.26) <= 63) {
      return <div className="result-text">
        <p><strong>Your church is doing well but not functioning at its best.</strong> There's room for improvement, which is a good thing as it gives you an opportunity to take intentional steps toward being effective.</p>
      </div>
    } else if (Math.round(score * 1.26) >= 0 && Math.round(score * 1.26) <= 21) {
      return <div className="result-text">
        <p><strong>Your church needs improvement</strong> at activating the Great Commission and driving Kingdom impact. With the right resources and prioritized focus, you can do even better!</p>
      </div>
    }
  };

	const toggleTeamIndividual = (tab) => {
		if(tab == 1) {
			setCurrentView(0);
			setToggleIndividual(1);
		} else {
			setCurrentView(1);
			setToggleIndividual(0);
			changeIndividualAssessment(members[0].id)
		}
	}

	const updateIndividualAssessment = (e) => {
		let memberId 			= e.target.value;
		changeIndividualAssessment(memberId);
	}

	const changeIndividualAssessment = (id) => {
		let member 				= members.find(function (obj) { return obj.id == id; });
		console.log(member);
		console.log(questions);
		let sectionIds 		= [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});
		console.log(sectionIds);
		setSelected(member.id);
		setMemberScore(member.score);
		setMemberName(member.fullName);
		setMemSubScore1(member.subScore1);
		setMemSubScore2(member.subScore2);
		setMemSubScore3(member.subScore3);
		setMemSubScore4(member.subScore4);
		setMemUrgencyScore(member.addAnswer1);
		
		let section1Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[0]; });
		section1Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});

		setMemberSection1(section1Questions);
		
		let section2Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[1]; });
		section2Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection2(section2Questions);

		let section3Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[2]; });
		section3Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection3(section3Questions);
		
		let section4Questions 		= questions.filter(function (obj) { return obj.sectionId == sectionIds[3]; });
		section4Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection4(section4Questions);

	}

	const showAnswerText = (answer, questionId) => {

		let question			= questions.find(function (obj) { return obj.id == questionId; });
		let answerType 		= "green";

		if(question.answers.length == 2) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "green"; }
		} else if(question.answers.length == 3) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "orange"; }
			else if(answer == 3) { answerType = "green"; }
		} else if(question.answers.length == 4) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "green"; }
			else if(answer == 4) { answerType = "green"; }
		} else if(question.answers.length == 5) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "orange"; }
			else if(answer == 4) { answerType = "green"; }
			else if(answer == 5) { answerType = "green"; }
		}
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		
		if(answerVal && answerVal.desktop_text) {
			if(answerType == "red") {
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if(answerType == "green") {
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			}
		}

	}

	const toggleSectionDetails = (id) => {
		if(id == toggleSection) {
			setToggleSection(0);
		} else {
			setToggleSection(id);
		}
	}

	const toggleChange = (e) => {
		setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleIndividualChange = (e) => {
		// setToggleSection(!e.target.checked);
		if(e.target.checked) {
			toggleTeamIndividual(0);
		} else {
			toggleTeamIndividual(1)
		}
	}

	const toggleSectionDisplay = (id) => {
		if(id == toggleSection) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleSectionIcon = (id) => {
		if(id == toggleSection) {
			return <RemoveIcon />
		} else {
			return <ExpandMoreIcon />
		}
	}

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <RemoveIcon />
		} else {
			return <ExpandMoreIcon />
		}
	}

	const getLeftMargin = (scoreValue) => {
    if (window.innerWidth < 800 && scoreValue > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && scoreValue < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: scoreValue + "%" };
    }
  };

  const getBarTextLeft = (scoreValue) => {
    if (window.innerWidth < 650 && scoreValue > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

	const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

	// const tableArray = sections?.map(section => [section.section]);
	
	// members?.forEach(member => {
	// 	member?.answers.forEach((answer, index) => {
	// 		tableArray?.[index]?.push(answer.answer)
	// 	})
	// })
	
	const sortedSections = sections?.sort((a, b) => {
		if (a.id < b.id) return -1;
		else if (b.id > a.id) return 1;
		else return 0;
	});
	// console.log("sortedSections", sortedSections); console.log("sections", sections);
	const tableArray1 = sortedSections?.map(section => {
		let questionId = questions?.find(q => q.sectionId === section.id)?.id;
		return [{ section: section.section, sectionId: section.id, questionId: questionId }];
	});
	members?.forEach(member => {
		member?.answers.forEach((answer, index) => {
			let idx = tableArray1?.find(item => item.questionId === answer.question_id);
			idx?.push({ answer: answer.answer, name: member.fullName });
		})
	})

	tableArray1.forEach((tableArr) => {
		let nameScoreArr = [];
		members.forEach(mem => {
			mem.answers.forEach(ans => {
				if(ans.question_id === tableArr[0]?.questionId) {
					nameScoreArr.push({ name: mem.fullName, score: ans.answer })
				}
			})
		})
		tableArr[0].nameAndScore = nameScoreArr;
	});

	// console.log("tableArray", tableArray);
	console.log("tableArray1", tableArray1);

	// const tableArrayScoresAvg = tableArray?.map((item, index) => {
	// 	let sum = 0; 
	// 	let num = 0;
	// 	// start at index 1 as index 0 is section name like VISION, MISSION, etc 
	// 	for (let i = 1; i < item.length; i++) {
	// 		switch (item[i]) {
	// 			case 1: num = 1;
	// 				break;
	// 			case 3: num = 2;
	// 				break;
	// 			case 6: num = 3;
	// 				break;
	// 			default:
	// 				break;
	// 		}
	// 		sum += num;
	// 	}
	// 	return ({ scoreAverage: sum/members?.length, scoresAndNames: item });
	// })
	
	const tableArrayScoresAvg1 = tableArray1?.map((item, index) => {
		let sum = 0; 
		let num = 0;
		let sectionScore = 0;
		// start at index 1 as index 0 is section name like VISION, MISSION, etc 
		for (let i = 0; i < item[0]?.nameAndScore?.length; i++) {
			switch (item[0].nameAndScore[i].score) {
				case 1: num = 1;
					break;
				case 3: num = 2;
					break;
				case 6: num = 3;
					break;
				default:
					break;
			}
			sectionScore = sectionScore + item[0].nameAndScore[i].score;
			sum += num;

		}
		return ({ sectionScore: sectionScore, scoreAverage: sum/members?.length, section: item[0]?.section, sectionId: item[0]?.sectionId, questionId: item[0]?.questionId, nameAndScoreArr: item[0]?.nameAndScore });
	})

	console.log("tableArrayScoresAvg1", tableArrayScoresAvg1);

	// use tableArrayScoresAvg average scores to sort 
	// console.log("tableArrayScoresAvg", tableArrayScoresAvg);
	
	// const scoreSortedTableArray = tableArrayScoresAvg?.sort((a, b) => {
	// 	// console.log(a.scoreAverage, b.scoreAverage)
	// 	return a.scoreAverage - b.scoreAverage
	// });
	// console.log("scoreSortedTableArray", scoreSortedTableArray);
	const scoreSortedTableArray1 = tableArrayScoresAvg1?.sort((a, b) => {
		// console.log(a.scoreAverage, b.scoreAverage)
		if (a.sectionScore < b.sectionScore) return -1;
		else if (b.sectionScore > a.sectionScore) return 1;
		else return 0;
	});
	console.log("scoreSortedTableArray1", scoreSortedTableArray1);
	// console.log("tableArrayScoresAvg", tableArrayScoresAvg);

	// function findAvgScores() {
	// 	// skip index 0 as it is section name 
	// 	console.log("tableArray", tableArray);
	// 	tableArray.forEach((item, index) => {
	// 		let colorScores = { green: 0, yellow: 0, red: 0 };
	// 		for (let i = 1; i < item?.length; i++) {
	// 			switch (item[i]) {
	// 				case 1: colorScores.red += 1;
	// 				break;
	// 				case 3: colorScores.yellow += 1;
	// 				break;
	// 				case 6: colorScores.green += 1;
	// 				break;
	// 				default:
	// 					break;
	// 			}
	// 		}
	// 		let max = 0;
	// 		let maxColor = "";
	// 		for(let color in colorScores) {
	// 			if(colorScores[color] > max) {
	// 				max = colorScores[color];
	// 				maxColor = color;
	// 			}
	// 		}
	// 		console.log(maxColor, colorScores[maxColor]);
	// 		console.log(colorScores);
	// 	})
	// }
	// findAvgScores();

	// console.log("scoreSortedTableArray", scoreSortedTableArray);
	// tableArray?.forEach(item => console.log(item))
	// members?.forEach(member => {
	// 	member?.answers.forEach((answer, index) => {
	// 		tableArray?.[index]?.push(answer.answer)
	// 	})
	// })
	// members?.forEach(member => {
	// 	member?.answers.forEach((answer, index) => {
	// 		tableArray?.[index]?.push(answer.answer)
	// 	})
	// })
	// members?.forEach(member => {
	// 	member?.answers.forEach((answer, index) => {
	// 		tableArray?.[index]?.push(answer.answer)
	// 	})
	// })
	
	// console.log("tableArray", tableArray);

	const showScoreColorBlock = (num) => {
		switch (num) {
			case 1:
				return <img src={redBlock} alt="score block" />;
			case 3:
				return <img src={yellowBlock} alt="score block" />;
			case 6:
				return <img src={greenBlock} alt="score block" />;
			default:
				return num;
		}
		
	}

	const accordionColor = (num) => {
    switch (num) {
      case 0:
        return "accordion red";
      case 1:
        return "accordion yellow";
      case 2:
        return "accordion green";
      default:
        return "accordion"
    }
  }

	// for combining question, answer, section name all in one array for score wise red, yellow, green accordions 
  const questionsAnswersCombined = questions?.map(question => {
    let currentAnswer = answers?.find(ans => ans.question_id === question.id);
    return ({ ...question, chosenAnswer: currentAnswer?.answer });
  })
  const queAnsAndSections = questionsAnswersCombined?.map(qa => {
    let currentSection = sections?.find(sec => sec.id === qa.sectionId);
    return ({ ...qa, sectionId: currentSection?.id, sectionName: currentSection?.section });
  })

	// console.log("queAnsAndSections", queAnsAndSections);
  // red, yellow, green respectively 
  const scoreWiseAnswers = [ [], [], [] ];
	for (let i = 0; i < accDataWithAvgScores?.length; i++) {
		let score = accDataWithAvgScores[i]?.questionAvgScore;
		if(score <= 1.67) scoreWiseAnswers[0].push(accDataWithAvgScores[i]);
		if(score > 1.67 && score <= 2.34) scoreWiseAnswers[1].push(accDataWithAvgScores[i]);
		if(score > 2.34 && score <= 3) scoreWiseAnswers[2].push(accDataWithAvgScores[i]);
	}

	console.log("scoreWiseAnswers", scoreWiseAnswers);
	// console.log("answers", answers);

	const memberScoresInfo = [[], [], []];
	const memberStats = members?.map(mem => {
		let name = mem.fullName.split(" ")[0];
		let memberId = mem.id;
		let memberInfo = { questionId: "", answer: "", name: "" }
		return mem.answers.map(ans => {
			switch (ans.answer) {
				case 1:
					memberScoresInfo[0].push({ questionId: ans.question_id, answer: ans.answer, name: name, memberId: memberId });
					break;
				case 3:
					memberScoresInfo[1].push({ questionId: ans.question_id, answer: ans.answer, name: name, memberId: memberId });
					break;
				case 6:
					memberScoresInfo[2].push({ questionId: ans.question_id, answer: ans.answer, name: name, memberId: memberId });
					break;
			
				default:
					break;
			}
		})
	})

	console.log("memberScoresInfo", memberScoresInfo);

	// console.log("scoreSortedTableArray", scoreSortedTableArray);


	const areasOfGrowth = () => {
		return (
			<div className="areas-for-growth">
				<h2>Evaluate areas for growth in your church</h2>
				{scoreWiseAnswers?.map((answer, index) => {
					let sectionName = answer.section;
					let queScore = answer.questionScore;
					return (
							<Accordion key={index} className={accordionColor(index)}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className="expand-icon" />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								{
									index === 0 ? <p className="white-text">{scoreWiseAnswers[0].length} areas that are broken</p> : index === 1 ? <p className="white-text">{scoreWiseAnswers[1].length} areas you need improvement in</p> : index === 2 ? <p className="white-text">{scoreWiseAnswers[2].length} areas where you're activating impact</p> : null
								}
							</AccordionSummary>
							{answer?.map((item, index) => {
								// console.log("item", item);
								let question = item.question;
								let sectionName = item.section;
								let queScore = item.questionScore;
								
								return (
									<Fragment key={index}>
										<AccordionDetails className="outer-accordion-details">
											<Accordion>
												<AccordionSummary expandIcon={<ExpandMoreIcon className="nested-expand-icon" />} aria-controls="nested-panel1a-content" id="nested-panel1a-header">
													<p><span className="section-title">{sectionName} </span></p>
												</AccordionSummary>
												<AccordionDetails className="nested-accordion-details">
													Score: <span className="score">{queScore}</span>
													<p>{question}</p>
													<ul className="name-score-list">
														{item.answers.map(data => {
															// console.log("data", data);
															let ansTxtClasses = data.answer === 6 ? "green" : data.answer === 3 ? "yellow" : data.answer === 1 ? "red" : "";
															return (<li><p className="user-name">{data.name.split(" ")[0]}</p>&nbsp;<span className={ansTxtClasses}>{data.answerTxt}</span></li>)
														})}
													</ul>
												</AccordionDetails>
											</Accordion>
										</AccordionDetails>
										<Divider />
									</Fragment>)
								})}
						</Accordion>
					)})
				}
			</div>
		)
	}

	const exportPDF = () => {

    updateIsExportingPDF(true);
    setPdfClass(true);
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=1200");
    document.querySelector("#root").classList.add("pdf-export-dashboard");
    document.querySelector("div.App").setAttribute("id", "pdf-conversion");
    
    setTimeout(function() {
      console.log("delayed function");
      
      // html2canvas((document.querySelector("#root")), { scale: 3 }).then(canvas => {
      //   // if you want see your screenshot in body.
      //   // document.body.appendChild(canvas);
      //   const imgData = canvas.toDataURL('image/svg');
      //   // const pdf = new jsPDF("p", "mm", "a4");
      //   const pdf = new jsPDF({ orientation: "p", unit: "px", format: "a4"});
      //   const imgProperties = pdf.getImageProperties(imgData);

      //   let pageWidth = pdf.internal.pageSize.getWidth();
      //   // let pageHeight = (imgProperties.height * pageWidth) / imgProperties.width;
      //   let pageHeight = pdf.internal.pageSize.getHeight();
      //   console.log(pageWidth, pageHeight);
      //   const widthRatio = pageWidth / canvas.width;
      //   // const heightRatio = pageHeight / canvas.height;
      //   const heightRatio = ((imgProperties.height * pageWidth) / imgProperties.width) / canvas.height;
      //   const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        
      //   const canvasWidth = canvas.width * ratio;
      //   // const canvasHeight = canvas.height * ratio;
      //   const canvasHeight = canvas.height;
      //   let heightLeft = canvasHeight;
      //   console.log(canvasWidth, canvasHeight);

      //   const marginX = (pageWidth - canvasWidth) / 2;
      //   const marginY = (pageHeight - canvasHeight) / 2;

      //   console.log("pageHeight", pageHeight);
      //   console.log("canvasHeight", canvasHeight);
      //   console.log("heightLeft", heightLeft);

      //   // console.log(canvas);
      //   // pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
      //   // pdf.addImage(imgData, 'PNG', marginX, 40, canvasWidth, canvasHeight);
      //   // pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);

      //   pdf.addImage(imgData, 'SVG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');
      //   heightLeft -= pageHeight;
        
      //   while(heightLeft > 0) {
      //     pdf.addPage();
      //     pdf.addImage(imgData, 'SVG', 0, (heightLeft - canvasHeight), pageWidth, pageHeight, undefined, 'FAST');
      //     heightLeft -= pageHeight;
      //   }

      //   pdf.save("Individual Results.pdf");
      //   // window.location.reload();
      // });

      // =================================================================

      html2canvas((document.querySelector("#root")), { scale: 3 }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;


        var doc = new jsPDF('p', 'mm', 'a4');
        var position = 0;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        doc.setLineWidth(0);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 297);
        heightLeft -= pageHeight;
        // console.log("canvas.height", canvas.height);
        // console.log("pageHeight", pageHeight);
        // console.log("heightLeft", heightLeft);
        // console.log("imgHeight", imgHeight);
        // console.log("position", position);
        
        
        while (heightLeft >= 0) {
          // console.log("second page");
          // console.log("pageHeight", pageHeight);
          // console.log("heightLeft", heightLeft);
          // console.log("imgHeight", imgHeight);
          position = heightLeft - (imgHeight);
          console.log("position", position);
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
          doc.setLineWidth(0);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 297);
          heightLeft -= pageHeight;
        }
        doc.save('Team Dashboard.pdf');

        // ======================================================

        // var imgData = canvas.toDataURL('image/png');
        // var imgWidth = 210; 
        // var pageHeight = 295;  
        // var imgHeight = canvas.height * imgWidth / canvas.width;
        // var heightLeft = imgHeight;
        // var doc = new jsPDF('p', 'mm');
        // var position = 0;

        // doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        // heightLeft -= pageHeight;

        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight;
        //   doc.addPage();
        //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        //   heightLeft -= pageHeight;
        // }
        // doc.save( 'file.pdf');

      });

      document.querySelector("div.App").removeAttribute("id");
      document.querySelector("#root").classList.remove("pdf-export-dashboard");
      document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
      setPdfClass(false);
      updateIsExportingPDF(false);

    }, 600);
  
    // clearTimeout(timeoutId);
    // console.log(`Timeout ID ${timeoutId} has been cleared`);


    // document.querySelector("div.App").removeAttribute("id");
    // document.querySelector("#root").classList.remove("pdf-export-dashboard");
    // document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
    // setPdfClass(false); 
    // updateIsExportingPDF(false);
  }

  if (isLoading) {
		return <div className="container dashboard loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

	if(currentView == 1) {
		return (
			<div>
				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleIndividual}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div>

				<div className="container results">

					<div style={{ height: '20px' }}></div>
					<div className="selection-form">
						<div className="form-detail">
							<TextField select hiddenLabel value={selected} onChange={(e) => {updateIndividualAssessment(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
								{members.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.fullName}'s
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="form-text">assessment score</div>
					</div>

					<div className="details-container">

						<div className="progress-bar">
							<div className="si_ass_result">
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: `calc(${memberScore}% - 2px` }}>
										{/* <div className="progress-text" style={getBarTextLeft(memberScore)}>{showProgressBarText(memberScore)}</div> */}
									</div>
									<span className="msi_score" style={getLeftMargin(memberScore)}>{memberScore}%</span>
								</div>
								
								<div className="pervalue">
									<h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
									<h4 className="nimp" style={{ minWidth: '20%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '20%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
									<h4 className="eexpe1" style={{ minWidth: '20%' }}></h4>
									<h4 className="eexpe2" style={{ minWidth: '18%' }}></h4>
									{/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
								</div>
								
								
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '20%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '20%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
									<h4 className="eexpe1" style={{ minWidth: '20%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
								</div>
								
								<div className="pervalue-text">
									<p className="not-aligned" style={{ marginLeft: '5%', fontWeight: score <= 45 ? 700 : 400 }}>Not<br />Aligned</p>
									<p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 700 : 400 }}>Poorly<br />Aligned</p>
									<p className="some-aligned" style={{ marginLeft: '42.5%', fontWeight: score > 40 && score <= 60 ? 700 : 400 }}>Somewhat<br />Aligned</p>
									<p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 700 : 400 }}>Well<br />Aligned</p>
									<p className="high-aligned" style={{ marginLeft: '85%', fontWeight: score > 80 ? 700 : 400 }}>Highly<br />Aligned</p>
								</div>

							</div>
						</div>
						<div className="spacer"></div>
						<div className="spacer"></div>

						<div className="score-section-container">

							{
								sections.map((data, index) => (
									<div key={index} className="score-section">
										<p className="score-txt">{memScores[index]}%</p>
										<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memScores[index]} /></div>
										<p className="score-name">{ data.section }</p>
									</div>)
							)}

						</div>

						{
							sections.map((data, index) => {
								
								let memberSection 		= [];
								if(index === 0) { 
									memberSection1.forEach((member) => {
										console.log(member);
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});									
								} else if(index === 1) {
									memberSection2.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index === 2) {
									memberSection3.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								} else if(index === 3) {
									memberSection4.forEach((member) => {
										memberSection.push({id: member.id, question: member.question, answer: member.answer});
									});
								}
								console.log(memberSection);

								return <div key={index} className="members-answered">
									<p className="section" onClick={() => toggleSectionDetails(index + 1)}>
										{data.section}
										{/* <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}> */}
										<a className="section-toggle">
											{ toggleSectionIcon(index + 1) }
										</a>
									</p>
									<div style={ toggleSectionDisplay(index + 1) }>
										{
											memberSection.map((data, index) => (
												<div key={index} className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer, data.id)}</div>
											)
										)}
									</div>
								</div>
							}
						)}

						<div style={{ height: "40px" }}></div>
						
					</div>
					
				</div>
			</div>
		)
	} else {
		return (
			<div>
				{/* Toggle Section */}
				{/* <div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="toggle-individual-group">
					<div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
					<Switch
						checked={!toggleSection}
						onChange={toggleIndividualChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
				</div> */}
	
				<div className="container dashboard">
					<div className="details-container">
	
						{/* {showTitleText()} */}
						<h1>Your church's average Activation score is {Math.round(score * 1.26)}</h1>

						<div className="progress-bar">
							<div className="si_ass_result">
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: `calc(${score}% - 2px)` }}>
										{/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
									</div>
									<span className="msi_score" style={getLeftMargin(score)}>{Math.round(score * 1.26)}</span>
								</div>
								<div className="pervalue">
									<h4 className="nimp" style={{ minWidth: '17%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '33%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '50%' }}></h4>
									{/* <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4>
									<h4 className="eexpe2" style={{ minWidth: '25%' }}></h4> */}
									{/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
								</div>
								
								
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '17%' }}></h4>
									<h4 className="mexpe" style={{ minWidth: '33%' }}></h4>
									{/* <h4 className="eexpe" style={{ minWidth: '50%' }  }></h4> */}
									{/* <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4> */}
									{/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
								</div>
								
								{/* <div className="pervalue-text"> */}
									{/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
									{/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
									{/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
									{/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
								{/* </div> */}
							
								{/* <div className="pervalue-text">
									<p className="not-aligned" style={{ marginLeft: '9%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Not on<br />Track</p>
									<p className="not-aligned" style={{ marginLeft: '29%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Needs<br />Improvement</p>
									<p className="poor-aligned" style={{ marginLeft: '55%', fontWeight: score > 20 && windowWidth >= 830 ? 400 : 700 }}>Meets<br />Expectations</p>
									<p className="some-aligned" style={{ marginLeft: '79%', fontWeight: score > 40 && windowWidth >= 830 ? 400 : 700 }}>Exceeds<br />Expectations</p>
								</div> */}

							</div>
						</div>
						<div className="spacer"></div>
						<div className="spacer"></div>
	
						{showScoreText()}

						<div className="result-info">
							<h3>Understanding Your Team's Results</h3>
							<p>Below is a grid of your combined team's results. Each benchmark is ranked in order of total team score from lowest to highest.</p>
						</div>

						{/* { windowWidth > 650 ? (<div className="details-table"> */}
						<div className="details-table">

						{ windowWidth > 830 ? (
							<div className="table-legend">
								<p><img src={redBlock} alt="" />&nbsp;&nbsp;Broken</p>
								<p><img src={yellowBlock} alt="" />&nbsp;&nbsp;Needs Improvement</p>
								<p><img src={greenBlock} alt="" />&nbsp;&nbsp;Activating Impact</p>
							</div>
							) : (
							<div className="table-legend-mobile">
								<div className="legend-section">
									<img src={redBlock} alt="" />
									<p>Broken</p>
								</div>
								<div className="legend-section">
									<img src={yellowBlock} alt="" />
									<p>Needs Improvement</p>
								</div>
								<div className="legend-section">
									<img src={greenBlock} alt="" />
									<p>Activating Impact</p>
								</div>
							</div>)}

							{windowWidth <= 650 && <p className="table-scroll-note"><em><span className="asterisk">*</span>Please scroll horizontally to see all scores.</em></p>}

							<TableContainer component={Paper} sx={{ p: 2, width: "auto" }}>
							<Table sx={{ minWidth: 300 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="center" className="table-header first-column">TEAM MEMBER</TableCell>
										{members?.map(member => <TableCell key={member.id} align="center" title={member.fullName + ", ID: " + member.id} className="member-name">{member.fullName.split(" ")[0]}</TableCell>)}
										{/* {members?.map(member => <TableCell key={member.id} align="center">{member.fullName.split(" ")[0]}</TableCell>)}
										{members?.map(member => <TableCell key={member.id} align="center">{member.fullName.split(" ")[0]}</TableCell>)}
										{members?.map(member => <TableCell key={member.id} align="center">{member.fullName.split(" ")[0]}</TableCell>)} */}
									</TableRow>
									<TableRow>
										<TableCell align="center" className="table-header first-column">INDIVIDUAL SCORE</TableCell>
										{members?.map(member => <TableCell key={member.id} align="center">{Math.round(member.score * 1.26)}</TableCell>)}
										{/* {members?.map(member => <TableCell key={member.id} align="center">{Math.round(member.score * 1.26)}</TableCell>)}
										{members?.map(member => <TableCell key={member.id} align="center">{Math.round(member.score * 1.26)}</TableCell>)}
										{members?.map(member => <TableCell key={member.id} align="center">{Math.round(member.score * 1.26)}</TableCell>)} */}
									</TableRow>
								</TableHead>
								
								<TableBody>
									{/* {
										scoreSortedTableArray?.map((item, index) => {
											let avg = item.scoreAverage;
											let color = "";
											// lowest score = 1 & highest score = 3 
											// score range (3 - 1 = 2) = possible scores will be between 1 to 3
											// score categories = 3 (red, yellow, green) 
											// score range / score categories = 2/3 = 0.667

											avg < 1.67 ? color = "red" : avg >= 1.67 && avg < 2.34 ? color = "yellow" : avg >= 2.34 && avg <= 3 ? color = "green" : color = "";

											return (<TableRow>
												{item.scoresAndNames.map((val, index) => {
													return index === 0 ? <TableCell key={index} align="center" className={`${color}-text first-column`}>{val}</TableCell> : <TableCell key={index} align="center">{showScoreColorBlock(val)}</TableCell>
												}
												)}
											</TableRow>)
										})
									} */}
									{
										scoreSortedTableArray1?.map((item, index) => {
											let avg = item.scoreAverage;
											let color = "";
											// lowest score = 1 & highest score = 3 
											// score range (3 - 1 = 2) = possible scores will be between 1 to 3
											// score categories = 3 (red, yellow, green) 
											// score range / score categories = 2/3 = 0.667

											avg < 1.67 ? color = "red" : avg >= 1.67 && avg < 2.34 ? color = "yellow" : avg >= 2.34 && avg <= 3 ? color = "green" : color = "";

											return (<TableRow>
												<TableCell key={item.section + index} align="center" className={`${color}-text first-column`}>{item.section}</TableCell>
												{item.nameAndScoreArr?.map((val, idx) => {
													// console.log(item.section, val);
													return <TableCell key={idx + item.section} align="center">{showScoreColorBlock(val.score)}</TableCell>
												}
												)}
											</TableRow>)
										})
									}
								</TableBody>
							</Table>
							</TableContainer>

						</div>

						<div className="pdf-btn">
							{/* <Button id="pdf-convert-btn" onClick={() => exportPDF()}> <Download className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button> */}
							<a href={downloadLink} download className="csv-download-link"><Button id="pdf-convert-btn"> <Download className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button>
            </a>
						</div>

						{/* </div>) : areasOfGrowth() } */}
							
	
						{/* <div className="result-sections">
	
							{
								sections.map((data, index) => (
								<div key={index} className="score-section">									
									<p className="score-txt">{scores[index]}%</p>
									<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={scores[index]} /></div>
									<p className="score-name">{ data.section }</p>
								</div>)
							)}
	
							<div className="score-section">
								FEEDBACK<br/><br/>
								<div className="score-txt">{subScore2}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
							</div>
	
							<div className="score-section">
								SERMON<br/><br/>
								<div className="score-txt">{subScore3}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
							</div>
	
							<div className="score-section">
								COMMUNITY<br/><br/>
								<div className="score-txt">{subScore4}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
							</div>
	
						</div> */}

						{/* <UrgencyScore {...{ urgencyComponentProps }} /> */}

						{/* <div>
							
							<div className="mobile_scores">
								<h2>INDIVIDUAL SCORES</h2>
								<div className="individual">

								{
									members.map((data, index) => (
										<>
											<div key={index} className="row">
												<div className="number">{ index + 1 }</div>
												<div className="name">{ data.fullName }</div>
												<div className="percent">{ data.score }%</div>
												<div className="toggle" onClick={() => toggleDetails(data.id)}>{ toggleMoreIcon(data.id) }</div>
											</div>
											<div className="scores" style={ toggleMoreDisplay(data.id) }>
												<div className="section_rows">
													<div className="section">STRATEGY</div>
													<div className="section_score">{ data.subScore1 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">PARTNERSHIP</div>
													<div className="section_score">{ data.subScore2 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">ENGAGEMENT</div>
													<div className="section_score">{ data.subScore3 }%</div>
												</div>
												<div className="section_rows">
													<div className="section">MISSION & VISION</div>
													<div className="section_score">{ data.subScore4 }%</div>
												</div>
											</div>
										</>
									)
								)}

								</div>
								
							</div>

							<table>
								<thead>
									<tr className="header">
										<th className="hash">#</th>
										<th>Name</th>
										{
											sections.map((data, index) => (
												<th key={index} className="center-cell">{ data.section }</th>
												)
												)}
										<th className="avg">Avg (%)</th>
									</tr>
								</thead>
								<tbody>

								{
									members.map((data, index) => (
										<tr key={index}>
											<td>{ index + 1 }</td>
											<td className="medium">{data.fullName}</td>
											{
												sections.map((section, index) => {
													let subScore;
													if(index == 0) { subScore = data.subScore1; }
													else if(index == 1) { subScore = data.subScore2; }
													else if(index == 2) { subScore = data.subScore3; }
													else if(index == 3) { subScore = data.subScore4; }
													else if(index == 4) { subScore = data.subScore5; }
													else if(index == 5) { subScore = data.subScore6; }
													else if(index == 6) { subScore = data.subScore7; }
													else if(index == 7) { subScore = data.subScore8; }
													return <td key={index} className="center-cell">{subScore}</td>
												}
												)}
											<td className="center-cell">{data.score}%</td>
										</tr>
									)
								)}
	
								<tr className='footer'>
									<th className="center-cell">&nbsp;</th>
									<th className="church-average">Team Average</th>
									{
										sections.map((data, index) => (
											<th key={index} className="center-cell">{scores[index]}</th>
											)
											)}
									<th className="center-cell">{score}%</th>
								</tr>
								</tbody>
							</table>
						</div> */}
					
						{/* <hr/> */}
	
						{/* <h2>Detailed Report:</h2>
										
						<div className="most-answered">
							<p className="green">Celebrate what you’re doing well</p>
							{
								tops.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>
	
						<div className="most-answered">
							<p className="red">Focus on what needs immediate attention</p>
							{
								bottoms.map((data, index) => (
									<div key={index} className="most-answered-detail">{index + 1}. { data.question }</div>
								)
							)}
						</div>
	
						<div className="alignment">
							<Tabs members={members} questions={questions} sections={sections} />
						</div> */}
	
						{/* <div className="hurdles-enablers">
							<h2 style={{ color: '#FF6645'}}>HURDLES</h2>
							<div className="hurdles">
								<ul>
								{enablers.map((data, index) => (
									data.answer != null ? <li key={index}>{ data.answer }</li> : ""
								))}
								</ul>
							</div>
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ color: '#73B76F' }}>ENABLERS</h2>
							<div className="enablers">
								<ul>
								{hurdles.map((data, index) => (
									data.answer != null ? <li key={index}>{ data.answer }</li> : ""
								))}
								</ul>
							</div>
						</div> */}
	
						<div style={{ height: "15px" }}></div>
	
					</div>
				
				</div>
	
			</div>
		);
	}
  

}
export default Dashboard;









