import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';

import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';

import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';

import Start from './Pages/Start';
import Assessment from './Pages/Assessment';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details';
import Results from './Pages/Results';
import Dashboard from './Pages/Dashboard';
import Invite from './Pages/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import PreAssessment from './Pages/PreAssessment';
import CallRequestSuccess from './Pages/CallRequestSuccess';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-WZNL392'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const [isLoading, setIsLoading]         = React.useState(false);
  const [name, setName]                   = useState('');

  useEffect(() => {
    getClient()
  }, []);

  const getClient = () => {
    setIsLoading(false);
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress size={60} /></div>
	}

  return (
    <div className="App">
      <AdditionalQuestionContextProvider>
        <BrowserRouter>
          <Header />
          <main className="main">
            <Routes>
              <Route path="/" exact index element={<Start setName={setName} />} />
              <Route path="/start" exact element={<Start setName={setName} />}></Route>
              <Route path="/before-you-start" exact element={<PreAssessment />}></Route>
              <Route path="/assessment" exact element={<Assessment />}></Route>
              <Route path="/assessment/:id/review" exact element={<Assessment />}></Route>
              <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route>
              <Route path="/details/:id" exact element={<Details />}></Route>
              <Route path="/results/:id" exact element={<Results />}></Route>
              <Route path="/invite-team/:id" exact element={<Invite />}></Route>
              <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
              <Route path="/call-request-success/:id" exact element={<CallRequestSuccess />}></Route>
              <Route path="/dashboard/:id" exact element={<Dashboard />}></Route>
            </Routes>
          </main>
          <Footer />
        </BrowserRouter>
      </AdditionalQuestionContextProvider>
    </div>
  );
}

export default App;
